/*
 * @Descripttion: 
 * @version: 
 * @Author: ZCP
 * @Date: 2021-05-16 23:09:12
 */
import {post,get} from '@/utils/axios'

export function projectList(param) {
  return  post("/public/api/square/projectList",param) 
}
export function userList(param) {
  return  post("/public/api/square/userList",param) 
}
export function usrProjectList(param) {
  return  post("/public/api/square/usrProjectList",param) 
}
export function usrInfo(param) {
  return  post("/public/api/square/usrInfo",param) 
}
