<template>
  <el-main style="height: 100%; padding: 0 !important">
    <div
      class="top hidden-sm hidden-xs"
      style="background-color: #7d36ff; position: fixed; z-index: 100000"
    >
      <div class="container">
        <nav class="navbar">
          <div class="navbar-header">
            <a class="navbar-brand" href="brand.html">
              <img src="images/umoke-all-m.png" />
            </a>
          </div>

          <div
            class="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
            style="margin-top: 10px"
          >
            <ul class="nav navbar-nav">
              <li class="active">
                <a href="brand.html"
                  >首页<span class="sr-only">(current)</span></a
                >
              </li>
              <li><a target="_blank" href="https://umoke.com">广场</a></li>

              <li class="dropdown hidden-xs">
                <a
                  href="brand.html"
                   >产品服务</a
                >
              </li>
              <li class="dropdown hidden-xs">
                <a
                  href="brand.html"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  >解决方案</a
                >
                <!-- <ul class="dropdown-menu text-center" aria-labelledby="dLabel">
                <i class="fa fa-caret-up fa-3x"></i>
                <li><a href="elebusinesssln.html">电商解决方案</a></li>
                <li><a href="mediasln.html">媒体解决方案</a></li>
                <li><a href="technology-solutions.html">科技解决方案</a></li>
                <li><a href="financial-solutions.html">金融解决方案</a></li>
              </ul> -->
              </li>
              <li><a href="brand.html">私有部署</a></li>
              <li><a href="#download">下载</a></li>
              <li><a href="http://cheen.umoke.com/">关于</a></li>
            </ul>
            <ul class="nav navbar-nav navbar-right hidden-xs">
              <li>
                <a
                  href="https://maker.umoke.com/"
                  style="border-radius: 15px; background-color: transparent"
                  class="login-btn"
                  >登录</a
                >
              </li>
              <!-- <li>
              <a href="experience.html">申请试用</a>
            </li> -->
            </ul>
          </div>
          <!-- /.navbar-collapse -->
        </nav>
      </div>
      <!--container-->
      <!-- <iframe src="https://pvr.umoke.com/t/5a30718a-18fa-4259-b724-c973848537f4" frameborder="no" width="700" height="500" ></iframe> -->
    </div>
    <div
      class="index"
      style="padding-top: var(--topBarH)"
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="0"
    >
      <div class="index_Carousel">
        <el-carousel indicator-position="odutside">
          <el-carousel-item>
            <div class="squarebannerbox hidden-sm hidden-xs">
              <div class="banner">
                <!-- <img src="/images/img1.png" alt="banner2" /> -->
                <img src="/images/img1.png" alt="banner2" />
              </div>
            </div>
            <div class="squarebannerboxM visible-sm-block visible-xs-block">
              <div class="bannerM">
                <img src="/images/img1_m.jpg" width="100%" alt="banner2" />
              </div>
            </div>
            <div class="Carousel">
              <div class="Carousel_input">
                <el-input
                  clearable
                  placeholder="请输入内容"
                  v-model="page.searchKey"
                  @keyup.enter.native="search"
                  class="input-with-select"
                >
                  <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="search"
                  ></el-button>
                </el-input>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <!-- <div class="Carousel_div">

      </div> -->
      </div>
      <div class="index_Button mHFlex">
        <div>
          <div>
            <el-button
              @click="activeIndex = 0"
              :type="activeIndex == 0 ? 'primary' : ''"
              round
              >发现</el-button
            >
            <span></span>
          </div>
          <div>
            <el-button
              @click="activeIndex = 1"
              :type="activeIndex == 1 ? 'primary' : ''"
              round
              >VR 展厅</el-button
            >
            <el-button
              @click="activeIndex = 2"
              :type="activeIndex == 2 ? 'primary' : ''"
              round
              >VR 文博</el-button
            >
            <el-button
              @click="activeIndex = 3"
              :type="activeIndex == 3 ? 'primary' : ''"
              round
              >VR 看房</el-button
            >
            <el-button
              @click="activeIndex = 4"
              :type="activeIndex == 4 ? 'primary' : ''"
              round
              >VR 看店</el-button
            >
            <el-tooltip
              class="item"
              effect="dark"
              content="敬请期待"
              placement="bottom"
            >
              <div class="disbled dis_btn">
                <el-button round disabled>环物</el-button>
              </div>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="敬请期待"
              placement="bottom"
            >
              <div class="disbled dis_btn">
                <el-button round disabled>爆炸特效</el-button>
              </div>
            </el-tooltip>
          </div>
        </div>

        <div>
          <el-button type="primary" style="padding: 10px 30px" @click="goMaker"
            ><i class="el-icon-upload el-icon--left"></i>发布作品</el-button
          >
        </div>
      </div>
      <div class="index_content">
        <div class="index_content_1">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="全部" name="first" style="display: flex">
              <div class="index_content_first" v-if="projectList.length > 0">
                <div v-for="(item, index) of projectList" :key="index">
                  <div
                    class="index_content_first_div"
                    v-if="item.name != undefined"
                  >
                    <div class="index_content_first_img" @click="viewPvr(item)">
                      <img v-if="item.cover" :src="`${item.cover}`" alt="#" />
                      <img v-else :src="`${item.entryThumb}`" alt="#" />
                    </div>
                    <div @click="viewPvr(item)">
                      <p class="templateTitle p_name">{{ item.name }}</p>
                    </div>
                    <div
                      class="index_content_first_userimg"
                      @click="routerUser(item.usr)"
                    >
                      <div>
                        <div v-if="item.usr.avatar">
                          <img :src="item.usr.avatar" alt="#" />
                        </div>
                        <div v-else>
                          <img src="../assets/img/2.gif" alt="#" />
                        </div>
                        <p class="ftsz12 u_name">{{ item.usr.name }}</p>
                      </div>
                      <div class="index_content_first_uie">
                        <p class="el-icon-view el-icon--left clc3">
                          <span class="ftsz14 clc3"
                            >&nbsp; &nbsp;{{ item.viewNum }}</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="index_content_first_div" v-else></div>
                </div>
              </div>
              <div class="no-data" v-else>
                <span class="no-data"
                  >共找到 0 个与 {{ page.searchKey }} 相关的内容</span
                >
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="作品" name="second">配置管理</el-tab-pane> -->
            <!-- <el-tab-pane label="作者" name="third">角色管理</el-tab-pane> -->
          </el-tabs>
        </div>
        <div class="center">
          <div class="index_content_2">
            <div class="index_content_2_1" style="visibility: hidden">
              <el-select v-model="value" size="small" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="index_content_2_2" style="margin-left: 10px">
              <el-select v-model="value1" size="small" placeholder="请选择">
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="index_content_2_2 mHBlock">
            <h3>发现优秀创作者</h3>
            <!-- <p>您可以通过查找优秀创作者，并获取他们的最新作品</p> -->
            <div
              class="index_content_2_2_1"
              v-for="(item, index) of usrList"
              :key="index"
              @click="routerUser(item)"
            >
              <div v-if="!item.avatar">
                <img src="../assets/img/2.gif" alt="#" />
              </div>
              <div v-else><img :src="item.avatar" alt="#" /></div>
              <div class="ftsz12">
                <p>{{ item.describe }}</p>
                <p class="clc3">{{ item.name }}</p>
              </div>
            </div>
            <!-- <div class="ftsz14"><ro#c3c3c3uter-link to="/">查看更多</ro#c3c3c3uter-link></div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="bottom">
      <!--大屏幕显示footer-->
      <div class="hidden-sm hidden-xs">
        <div class="container">
          <div class="row footer-2">
            <div class="col-sm-3">
              <h4>产品</h4>
              <ul>
                <li><a href="brand.html">VR 全景环物</a></li>
                <li><a href="brand.html">VR 博物馆</a></li>
                <li><a href="brand.html">VR 全景漫游</a></li>
                <li><a href="brand.html">VR 全景视频</a></li>
                <li><a href="brand.html">VR 线上展馆</a></li>
              </ul>
            </div>
            <div class="col-md-3">
              <h4>解决方案</h4>
              <ul>
                <li><a href="brand.html">党建行业解决方案</a></li>
                <li><a href="brand.html">智慧城市解决方案</a></li>
                <li><a href="brand.html">会展行业解决方案</a></li>
                <li><a href="brand.html">文博行业解决方案</a></li>
              </ul>
            </div>
            <div id="download" class="col-md-3">
              <h4>下载目客 VR</h4>
              <ul>
                <li><a href="#1">iOS 版</a></li>
                <span style="opacity: 0.3">敬请期待...</span>
                <li><a href="#1">Android 版</a></li>
                <li><img src="images/app-qr.png" alt="二维码" /></li>
                <li><a href="#1">HUAWEI VR GLASS 版</a></li>
                <span style="opacity: 0.3">敬请期待...</span>
              </ul>
            </div>
            <!-- <div class="col-md-2">
            <h4>便捷入口</h4>
            <ul>
              <li><a href="brand.html">公众号大师平台</a></li>
              <li><a href="brand.html">大数据平台</a></li>
              <li><a href="brand.html">公众号排行入口</a></li>
            </ul>
          </div> -->
            <div class="col-md-3">
              <h4>联系我们</h4>
              <ul>
                <li>电话：17753293989</li>
                <li>官方微信号：目客VR</li>
                <li>邮箱：578911542@qq.com</li>
                <li>地址：青岛市市北台柳路229号</li>
                <li><img src="images/qrcode_258.jpg" alt="二维码" /></li>
              </ul>
            </div>
          </div>
        </div>
        <!--footer-2-->
        <hr />
      </div>
      <!--hidden-->

      <!--hidden-->
      <div class="footer-3 text-center">
        Copyright Cheen Tech Inc. All Rights Reserved.
        <div>
          <a
            style="color: gray; font-size: 12px"
            target="_blank"
            href="https://beian.miit.gov.cn/"
            data-v-4efe8b62=""
            >鲁ICP备19054926号-1</a
          >
          <a
            style="color: gray; font-size: 12px"
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37020302371899"
            data-v-4efe8b62=""
          >
            鲁公网安备 37020302371899号</a
          >
        </div>
      </div>
    </div>
  </el-main>
</template>
<script>
import { projectList, userList } from "../api/square.js";
export default {
  data() {
    return {
      activeIndex: 0,
      baseURL: process.env.VUE_APP_BASE_API,
      activeName: "first",
      options: [
        {
          value: "1",
          label: "全部平台",
        },
        {
          value: "2",
          label: "手机",
        },
        {
          value: "3",
          label: "网页/电视",
        },
      ],
      value: "1",
      options1: [
        {
          value: "1",
          label: "推荐",
        },
        {
          value: "2",
          label: "热门",
        },
        {
          value: "3",
          label: "最新",
        },
      ],
      value1: "1",
      page: {
        pageNumber: Number(0),
        pageSize: Number(12),
        projectCount: Number(0),
        searchKey: "",
      },
      projectList: [],
      usrList: [],
      busy: false,
    };
  },
  watch: {
    'page.searchKey': function (val) {
      val = val.trim()
      if (!val) {
        this.search()
      }
    },
  },
  methods: {
    viewPvr(item) {
      if (process.env.VUE_APP_ENV != "production") {
        window.open(process.env.VUE_APP_PVR + "#" + item.id, "_blank");
      } else {
        window.open(process.env.VUE_APP_PVR + "t/" + item.id, "_blank");
      }
    },
    goMaker() {
      window.open('https://maker.umoke.com/dashboard', "_blank");
    },
    routerUser(item) {
      // console.log(item)
      // this.$router.push({
      //   name:"user",
      //   params:{
      //     usrId:item.id,
      //     avatar:item.avatar,
      //     name:item.name
      //   }
      // })

      let routeUrl = this.$router.resolve({
        path: "/user/" + item.id,
      });
      window.open(routeUrl.href, "_blank");
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    funGetProjectList(param) {
      this.busy = true;

      projectList(param).then((res) => {
        // console.log(res)
        if (res.code == 200) {
          console.log("成功");
          let data = res.data.projectList;
          // console.log(data.length)
          data.forEach((item) => {
            this.projectList.push(item);
          });
          if (data.length % 4 != 0) {
            let n = data.length % 4;
            console.log(n);
            for (let i = 0; i < 4 - n; i++) {
              this.projectList.push({});
              // console.log(i)
            }
          }

          // this.projectList=data
          this.page.projectCount = res.data.projectCount;
          this.busy = false;
          console.log(this.page.projectCount);

          // console.log(res)
        } else {
          console.log("失败");
        }
        // console.log(param)
      });
    },
    search() {
      this.projectList = []
      this.page.pageNumber = 0
      this.funGetProjectList(this.page);
    },
    funGetuserList(param) {
      userList(param).then((res) => {
        if (res.code == 200) {
          // console.log("成功")
          let data = res.data.userList;
          this.usrList = data;
          // console.log(data)
        } else {
          // console.log("失败")
        }
      });
    },
    loadMore() {
      this.page.pageNumber++;

      // console.log(this.page.pageNumber,this.page.projectCount/this.page.pageSize)
      if (this.page.pageNumber <= this.page.projectCount / this.page.pageSize) {
        this.funGetProjectList(this.page);
      }

      // console.log(111)
    },
  },
  mounted() {
    this.funGetProjectList(this.page);
    this.funGetuserList();
  },
};
</script>
<style lang="less" scoped>
.info {
  a:link {
    text-decoration: none;
    color: white;
  }
  　　 a:active {
    text-decoration: blink;
  }
  　　 a:hover {
    text-decoration: underline;
    color: white;
  }
  　　 a:visited {
    text-decoration: none;
    color: white;
  }
  a:link {
    text-decoration: none;
    color: white;
  }
  　　 a:active {
    text-decoration: blink;
  }
  　　 a:hover {
    text-decoration: underline;
    color: white;
  }
  　　 a:visited {
    text-decoration: none;
    color: white;
  }
}

.no-data {
  width: 100%;
  text-align: center;
  margin-top: 100px;
}
.dis_btn {
  display: inline-block !important;
  margin: 0 10px;
}

.index_Carousel {
  /deep/.el-carousel__container {
    height: var(--bannerH);
  }
}
.Carousel_input {
  /deep/.el-input__inner {
    padding: var(--searchPadding);
    font-size: var(--searchFS);
    height: var(--searchHeight);
    line-height: var(--searchHeight);
    color: rgb(255, 255, 255);
  }
  /deep/.el-input-group:focus-within {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 4px !important;
    input {
      color: rgba(31, 41, 46, 1);
    }
    button {
      color: #1f292e !important;
    }
  }
  /deep/.el-input__inner:focus {
    // background-color: rgba(255, 255, 255, 0.6);
    border-color: #dcdfe6;
    color: rgba(31, 41, 46, 1);
  }
  /deep/.el-input__inner::-webkit-input-placeholder {
    color: rgba(31, 41, 46, 0.6);
  }
  /deep/.el-input__inner:focus::-webkit-input-placeholder {
    color: rgba(31, 41, 46, 0.7);
  }

  /deep/.el-input-group__append {
    font-size: 16px;
    color: rgb(255, 255, 255);
  }
  /deep/.el-button,
  .el-button--default {
    background-color: rgba(255, 255, 255, 0.1);
  }
  /deep/.el-input-group__append {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
  /deep/.el-input-group {
    width: var(--searchW);
    background-color: rgba(255, 255, 255, 0.1);
  }
  /deep/.el-input__inner {
    width: var(--searchW);
    margin-right: -1px;
    background-color: rgba(255, 255, 255, 0.1);
  }
  position: absolute;
  margin: auto;
  bottom: var(--searchBtn);
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
}

.Carousel {
  width: 100%;
  height: 100%;
  background: var(--banner);
  background-position-y: center;
  background-size: 100% 100%;
}

.index_Carousel {
  /deep/ li {
    display: none !important;
    // background-color:rgb(242, 242, 242);
    // border: 0px solid #DCDFE6!important;
  }
}
.index_Button {
  display: flex;
  justify-content: space-between;
  padding: 40px 90px 40px 70px;
  border-bottom: 1px solid #e8e8e8;

  /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 4px;
    background: #e5e5e5;
  }

  /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: #1e6abc;
  }
}
.index_Button > div {
  white-space: nowrap;
}
.index_Button > div:first-child {
  display: flex;
}
.index_Button > div:first-child span {
  border-right: 1px solid #c3c3c3;
  margin: 0px 10px;
}
.center {
  display: var(--mobileHiddenBlock);
  position: relative;
}
.index_content_2 {
  /deep/.el-input__inner {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: none;
  }
  /deep/.el-input__inner:not(input, textarea) {
    -webkit-touch-callout: none;
    // -webkit-user-select: none;
  }

  position: absolute;
  left: -245px;
  z-index: 10000;
}
.index_content {
  display: flex;
  justify-content: space-around;
  padding: var(--contentPadding);
  background: var(--contentBGC);
  .index_content_2 {
    display: flex;
    margin-top: 10px;
    .index_content_2_1 {
      /deep/.el-input {
        width: 120px;
      }
    }
    .index_content_2_2 {
      /deep/.el-input {
        width: 80px;
      }
    }
  }
  // justify-content: space-between;
  /deep/.el-tabs__nav-wrap::after {
    background-color: #fff;
  }
}
.index_content_1 {
  flex: 0 0 80%;

  /deep/.el-tabs__header {
    display: var(--mobileHiddenBlock);
  }
}
.index_content > :nth-child(2) {
  flex: 0 0 15%;
}
.index_content_2_2 > h3 {
  margin-top: 20px;
  color: #1f292e;
  font-size: 16px;
}
.index_content_2_2 > p {
  color: #64777f;
  font-size: 12px;
  margin-top: 10px;
  line-height: 16px;
}
.index_content_2_2_1 {
  padding: 6px;
  transition: 0.3s;
  border-radius: 5px;
  margin-top: 14px;
  display: flex;
  p {
    color: #5b6b73;
  }
}
.index_content_2_2_1:hover {
  background-color: #f2f2f2;
}
.index_content_2_2_1 > div:nth-child(2) {
  line-height: 26px;
  margin-left: 5px;
}
.index_content_2_2_1 > div:first-child {
  border-radius: 50%;
  width: 46px;
  height: 46px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.index_content_first {
  padding-top: var(--contentPT);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .index_content_first_div {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    overflow: hidden;
    background: var(--itemBGC);
    width: var(--itemW);
    border-radius: var(--itemIMGBR);
    margin-bottom: var(--itemMB);

    .p_name {
      max-width: var(--itemW);
      margin-left: var(--ml);
    }
    .u_name {
      max-width: var(--itemNameW);
    }
  }
}
.index_content_first_div > div:first-child {
  width: 100%;
  margin-bottom: 12px;
  height: 100%;
  border-radius: var(--itemIMGBR);

  img {
    width: var(--itemW);
    height: var(--itemH);
    // object-fit: cover;
    border-radius: var(--itemIMGBR);
  }
}

.index_content_first_userimg {
  display: flex;
  justify-content: space-between;
  margin-left: var(--ml);
  margin-bottom: 3px;
}
.index_content_first_userimg > div {
  display: flex;
}
.index_content_first_userimg > div > div {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  overflow: hidden;
}
.index_content_first_userimg > div p {
  line-height: 26px;
  padding-left: 10px;
}
.index_content_first_userimg > div img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.index_content_first_uie {
  border: 1px solid #fff;
  margin-right: 5px;
  border-radius: 5px;
}
.index_content_first_uie:hover {
  border: 1px solid #1f292e !important;
}
.index_content_first_img {
  overflow: hidden;
}
.index_content_first_img > img {
  transition: 0.8s;
}
.index_content_first_div:hover {
  // border: 1px solid red;
  transition: 0.5s;
  // box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
  .index_content_first_uie {
    box-sizing: border-box;
    border: 1px solid #a7b0b6;
  }
  .index_content_first_img > img {
    transform: scale(1.1);
  }
}
</style>
