<template>
  <div class="user">
    <div class="user_head_img">
      <img src="../assets/img/bg_author_page.png" alt="" />
      <div class="usrInfo mVFlex">
        <div v-if="user.avatar">
          <img class="uavatar" :src="user.avatar" alt="" />
        </div>
        <div v-else>
          <img class="uavatar" src="../assets/img/2.gif" alt="" />
        </div>
        <h3>{{ user.name }}</h3>
        <!-- <h4>产品UI</h4> -->
        <p>优秀作品比赛评委 · 独立创作者</p>
      </div>
    </div>
    <div class="user_content">
      <div class="content_box_left mHBlock">
        <div>
          <div v-if="user.avatar"><img :src="user.avatar" alt="" /></div>
          <div v-else><img src="../assets/img/2.gif" alt="" /></div>
          <h3>{{ user.name }}</h3>
          <!-- <h4>产品UI</h4> -->
          <p>优秀作品比赛评委 · 独立创作者</p>
        </div>
        <div>
          <div>浏览</div>
          <div></div>
          <div>111</div>
        </div>
      </div>
      <div class="content_box_rigth">
        <div class="mHBlock">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="作品" name="pro"> </el-tab-pane>
          </el-tabs>
        </div>
        <div class="content_box_rigth_obj">
          <div
            class="item"
            v-for="(item, index) of Projects"
            :key="index"
            @click="viewPvr(item)"
          >
            <div class="rigth_obj_img">
              <img :src="item.entryThumb" alt="#" />
            </div>
            <p class="rigth_obj_title">{{ item.name }}</p>
            <div
              class="index_content_first_userimg"
              @click="routerUser(item.usr)"
            >
              <div>
                <div v-if="user.avatar">
                  <img :src="user.avatar" alt="#" />
                </div>
                <div v-else>
                  <img src="../assets/img/2.gif" alt="#" />
                </div>
                <p class="ftsz12 u_name">{{ user.name }}</p>
              </div>
              <p class="el-icon-view el-icon--left clc3 mt10">
                <span class="ftsz14 clc3">&nbsp; &nbsp;{{ item.viewNum }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { usrProjectList,usrInfo } from "../api/square.js";
export default {
  data() {
    return {
      activeName: "pro",
      user: {},
      Projects: []
    }

  },
  methods: {
    viewPvr(item) {
      if (process.env.VUE_APP_ENV != "production") {
        window.open(process.env.VUE_APP_PVR + "#" + item.id, "_blank");
      } else {
        window.open(process.env.VUE_APP_PVR + "t/" + item.id, "_blank");
      }
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    getAllData(param) {
      usrProjectList(param).then(res => {
        this.Projects = res.data;
      })
      usrInfo(param).then(res => {
        console.log(res)
        this.user = res.data;
      })
    }
  },
  
  mounted() {
    // console.log(this.$route.params)
    // this.user = this.$route.query;

    let usrId = this.$route.params.id
    this.user.usrId = usrId
    // console.log(this.user)
    this.getAllData({ usrId })
  }


};
</script>
<style lang="less" scoped>
.user {
  min-height: 100vh;
  background-color: rgb(246, 247, 248);
  .user_head_img {
    display: flex;
    img {
      background-position-y: center;
      background-size: 100% auto;
      width: 100%;
      height: 200px;
    }
    .usrInfo {
      position: absolute;
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      h3 {
        font-size: 28px;
        color: rgb(65, 80, 88);
        margin-top: 9px;
      }
      h4 {
        font-size: 16px;
        color: rgb(65, 80, 88);
        margin: 19px 0px;
      }
      p {
        font-size: 12px;
        color: rgb(65, 80, 88);
        margin: 12px 0px;
      }
    }
    .uavatar {
      width: 80px;
      height: 80px;
      border-radius: 40px;
    }
    margin-bottom: var(--uBannerMB);
  }
}
.mt10 {
  margin-top: 7px;
}

.index_content_first_userimg {
  display: flex;
  justify-content: space-between;
  margin-left: var(--ml);
  margin-bottom: 3px;
}
.index_content_first_userimg > div {
  display: flex;
}
.index_content_first_userimg > div > div {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  overflow: hidden;
}
.index_content_first_userimg > div p {
  line-height: 30px;
  padding-left: 10px;
}
.index_content_first_userimg > div img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.user_content {
  margin-top: var(--ucMT);
  width: var(--ucW);
  display: flex;
  justify-content: center;
  margin-left: var(--ucML);
  .content_box_left > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    border-radius: 10px;
    background-color: #fff;
    // display: flex;
    margin-bottom: 20px;
    padding-bottom: 20px;
    h3 {
      font-size: 28px;
      color: rgb(65, 80, 88);
      margin-top: 39px;
    }
    h4 {
      font-size: 16px;
      color: rgb(65, 80, 88);
      margin: 19px 0px;
    }
    p {
      font-size: 12px;
      color: rgb(65, 80, 88);
      margin: 12px 0px;
    }
    div:first-child {
      width: 128px;
      height: 128px;
      border-radius: 50%;
      margin-top: -64px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
  .content_box_left > div:last-child {
    // width: 280px;
    display: flex;
    justify-content: space-around;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    font-size: 16px;
    color: rgb(65, 80, 88);
    // margin-bottom: 10px;

    div:nth-child(2) {
      border-left: 1px solid #979ca2;
    }
  }
  .content_box_rigth {
    border-radius: 10px;
    padding: var(--ucP);
    width: var(--ucW);
    margin-left: var(--listML);
  }
  /deep/.el-tabs__nav-wrap::after {
    background-color: #fff;
  }
  /deep/.el-tabs__item.is-active {
    font-size: 18px;
  }
  /deep/.el-tabs__header {
    background-color: #fff;
    padding: 20px;
    margin-top: -20px;
    border-radius: 10px;
  }
}
.content_box_rigth_obj > div:hover {
  cursor: pointer;
  .rigth_obj_img {
    img {
      transition: 0.8s;
    }
    img {
      transform: scale(1.1);
    }
  }
}
.content_box_rigth_obj {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: var(--contentBGC);
  // width: 272px;

  .item {
    margin-bottom: var(--itemMB);
  }
  div {
    margin-bottom: 3px;
    flex: var(--uItemFlex);
    overflow: hidden;
    border-radius: var(--itemIMGBR);
    background: var(--itemBGC);
  }
  .rigth_obj_img {
    width: var(--uItem);
    margin-bottom: 10px;
    img {
      width: var(--uItem);
      height: var(--itemH);
      border-radius: var(--itemIMGBR);
      transition: 0.8s;
    }
    img:hover {
      transform: scale(1.1);
    }
  }
  .rigth_obj_title {
    font-weight: 500;
    color: rgb(65, 80, 88);
    font-size: 14px;
    margin-bottom: 8px;
    margin-left: var(--ml);
  }
}
</style>
