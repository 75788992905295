/*
 * @Descripttion:
 * @version:
 * @Author: ZCP
 * @Date: 2020-12-13 13:28:08
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-06-18 13:37:33
 */
import axios from 'axios'
import qs from 'qs'
import { Message } from 'element-ui'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.baseURL = process.env.VUE_APP_BASE_API// url = base url + request url

//这里柚子引入的是element-ui中的提示框和加载框，用其他ui组件的要换一下
// 响应时间
axios.defaults.timeout = 115 * 1000
// 配置cookie
// axios.defaults.withCredentials = true
// axios.defaults.crossDomain=true
// 配置请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// 静态资源
// Vue.prototype.$static = ''
// 配置接口地址
//这里的接口地址是你地址的相同的前半部分，方便管理
// axios.defaults.baseURL = process.env.VUE_APP_BASE_API, // url = base url + request url

// var loadingInstance
// POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(
    config => {
        if (config.method === 'post') {
            if (config.headers['Content-Type'] != 'mu   ltipart/form-data;charset=UTF-8') {
                config.data = qs.stringify(config.data)
            }
        }
        //TODO 判断用户是否登录 token 判断可能会过期 重定向
        if (localStorage.getItem('token') && config.url != "public/api/usr/login") {
            const token = localStorage.getItem('token');
            config.headers.authorization = 'Bearer ' + token;
        }
        return config
    },
    err => {
        // ElMessage.error('请求错误')
        return Promise.reject(err)
    }
)
// 返回状态判断(添加响应拦截器)
axios.interceptors.response.use(
    res => {
        if (res.data.code > 100000) {//200 内的业务错误
            if (!window.hasJump) {
                window.hasJump = true
                Message.error(res.data.message)
                setTimeout(() => {
                    Message.error(res.data.message)
                    if (res.data.code.startsWith('1001') && res.data.code != '1001004') {
                        // window.open(process.env.VUE_APP_LOGIN_URL)
                        localStorage.setItem('token','');
                    }
                    setTimeout(() => {
                        window.hasJump = false
                    }, 2000);
                }, 1000);
            }


        }
        // loadingInstance.close()
        return res
    },
    err => {//非 200
        // loadingInstance.close()
        console.log('------' + err);
        Message.error('请求失败，请稍后再试')
        return Promise.reject(err)
    }
)
// 发送请求
export function post(url, params = {}) {
    // if (localStorage.getItem('token') && url != BizApi.usr.login) {
    // axios.defaults.headers.post['Authorization'] = 'application/x-www-form-urlencoded;charset=UTF-8'
    // }
    params.isLoading = true;
    return new Promise((resolve, reject) => {
        axios.post(url, params).then(
            res => {
                params.isLoading = false
                resolve(res.data)
            },
            err => {
                params.isLoading = false
                reject(err.data)
            }
        )
            .catch(err => {
                params.isLoading = false
            })
    })
}
export function get(url, params = {}) {
    params.isLoading = true;
    return new Promise((resolve, reject) => {
        axios.get(url, { params: params }).then(
            res => {
                params.isLoading = false
                // console.log("🚀 ~ file: axios.js ~ line 94 ~ returnnewPromise ~ res.data", res.data)
                resolve(res.data)
            },
            err => {
                params.isLoading = false
                // reject(err.data)
            })
            .catch(err => {
                params.isLoading = false
            })
    })
}

//上传图片
export function upload(url, f, fParam, lis) {
    let params = new FormData();
    for (let key in fParam) {
        if (fParam.hasOwnProperty(key)) {
            params.append(key, fParam[key]);//通过append向form对象添加数据
        }
    }
    params.append(fParam.project, f);//通过append向form对象添加数据
    // params.append('file', f);//通过append向form对象添加数据
    let config = {//添加请求头
        headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
        onUploadProgress: progressEvent => {
            let complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
            f.percent = complete
            lis(f)
            // this.progress = complete
        }
    };
    params.isLoading = true;
    return new Promise((resolve, reject) => {
        axios.post(url, params, config).then(
            res => {
                params.isLoading = false
                resolve(res.data)
            },
            err => {
                params.isLoading = false
                reject(err.data)
            }
        )
            .catch(err => {
                params.isLoading = false
            })
    })

}

export function downloadImg(url) {
    return axios.get(url, { responseType: 'blob' });
}